import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dataV from '@jiaminghi/data-view';
import axios from 'axios'//引入
// import VueAMap from 'vue-amap'; // 导入vue-amap库
 
// // 初始化高德地图的key值（需要去高德开放平台申请）
// const amapKey = '70912b2d761738055bb6c4dce3b9cd17';
// Vue.use(VueAMap);
// VueAMap.initAMapApiLoader({
//     key: amapKey, // 设置高德地图API Key
//     plugin: ['AMap.Geolocation'], // 加载定位插件等其他插件
// });

// import VueAMap from 'vue-amap'
// Vue.use(VueAMap)
// VueAMap.initAMapApiLoader({
//   key: '242aeba0fec00d99d80448daabfbab0d',
//   plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PlaceSearch', 'AMap.Geolocation', 'AMap.Geocoder'],
//   v: '1.4.4',
//   uiVersion: '1.0'
// })

// 引入全局css
import './assets/scss/style.scss';
// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';
//引入echart
//4.x 引用方式

import * as echarts from 'echarts';
//5.x 引用方式为按需引用
//希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
//import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.prototype.$http = axios //正确的使用
Vue.config.productionTip = false;

// 全局注册
Vue.component('icon', Icon);
Vue.use(dataV);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
